import React from "react";

// React
import Modal from "react-modal";

// SVG
import Logo from "../../../images/logo/logo.inline.svg";
import LogoLetters from "../../../images/logo/logo-letters.inline.svg";

export default function TextModal(props) {
  return (
    <div>
      <Modal
        isOpen={props.isOpen}
        onRequestClose={() => {
          props.close();
        }}
        shouldCloseOnOverlayClick={true}
        style={{
          overlay: { zIndex: 1000 },
          content: {
            background: "bg-gray-100",
          },
        }}
        //contentLabel="Modal"
        ariaHideApp={false}
      >
        {props.title === "JERRY MORRISSEY" ? (
          <div
            className="fixed z-10 inset-0 overflow-y-auto"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div className="flex justify-centermy-52 md:my-0 h-max md:max-h-screen px-4 text-center sm:block sm:p-0">
              <div
                className="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity"
                aria-hidden="true"
              ></div>
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <div className="relative inline-block align-middle bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 max-w-3xl w-full sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <div className="p-2">
                      <div
                        className={
                          "flex flex-col items-center justify-center mb-4"
                        }
                      >
                        <Logo
                          className={"h-24 w-auto mx-auto pb-2 fill-slate-600"}
                        />
                        <LogoLetters
                          className={"h-auto w-32 mx-auto fill-slate-600"}
                        />
                      </div>
                      <h1 className="text-center text-3xl md:text-4xl font-extrabold tracking-tight ">
                        <span className="block text-slate-600">
                          JERRY MORRISSEY
                        </span>
                      </h1>
                      <p className="mt-2 text-mustard text-md font-bold text-center">
                        FOUNDER, CEO, & CHIEF INSPIRATIONAL OFFICER
                      </p>
                    </div>
                    <div className="flex flex-col space-y-5 my-4 text-left">
                      <p className="text-md text-black px-4 leading-relaxed font-serif">
                        ARITAE is the evolution of Jerry Morrissey’s 35+ years
                        of studying and working in the areas of personal growth,
                        development, leadership and athletic coaching. ARITAE
                        began as a self-leadership program developed and written
                        by Jerry in 2003-04 for young people called “Coaching
                        for Kids.”
                      </p>
                      <p className="text-md text-black px-4 leading-relaxed font-serif">
                        Jerry’s experience growing up playing sports in high
                        school, as well as college and eventually
                        semi-professional ice hockey, helped him realize that a
                        commitment to self- awareness, clarity, focus, personal
                        power and growth were instrumental in anyone’s
                        progression towards success.
                      </p>
                      <p className="text-md text-black px-4 leading-relaxed font-serif">
                        Jerry studied secondary education and psychology in
                        college. He coached a multitude of sports post college,
                        both girls and boys, youth as well as high school.
                      </p>
                      <p className="text-md text-black px-4 leading-relaxed font-serif">
                        His many experiences have shaped his perspective and
                        philosophy about the importance of self-discovery,
                        inspiration, focus, action, as well as strategizing,
                        planning and executing to achieve self-determined
                        “Success.”
                      </p>
                      <p className="text-md text-black px-4 leading-relaxed font-serif">
                        Jerry’s education and broad in depth study of personal
                        development, combined with his athletic coaching
                        experiences with young people became the foundation of
                        the ARITAE Self-Leadership Academy 5-Module Program.
                      </p>
                      <p className="text-md text-black px-4 leading-relaxed font-serif">
                        Every ARITAE player and coach teaches Jerry something
                        unique and always brings a new perspective to the
                        program. As a life-long learner himself, Jerry strives
                        to, through the Aritae coaches and community, bring this
                        same sense of exploration and inspiration to each player
                        in the program.
                      </p>
                    </div>
                  </div>
                </div>
                <button
                  className={
                    "absolute p-2 top-1 right-1 z-50 bg-white rounded-md text-gray-500"
                  }
                  onClick={() => {
                    props.close();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="fixed z-10 inset-0 overflow-y-auto"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div className="flex justify-center my-52 md:my-0 max-h-screen px-4 text-center sm:block sm:p-0">
              <div
                className="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity"
                aria-hidden="true"
              ></div>
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                <div>
                  {props.icon === "success" ? (
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                      <svg
                        className="h-6 w-6 text-green-600"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    </div>
                  ) : props.icon === "failure" ? (
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                      <svg
                        className="h-6 w-6 text-red-600"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                        />
                      </svg>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="mt-3 text-center sm:mt-5">
                    <h3
                      className="text-lg leading-6 font-medium text-gray-900"
                      id="modal-title"
                    >
                      {props.title}
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {props.description}
                      </p>
                    </div>
                  </div>
                </div>
                <button
                  className={
                    "absolute top-1 right-1 z-50 bg-white rounded-md text-gray-500"
                  }
                  onClick={() => {
                    props.close();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}
