import React from "react";

export default function LoadingButton(props) {
    return (
        <div>
            {props.type === "email" ? (<button type="button" className="w-full flex justify-center px-5 py-3 border border-transparent rounded shadow-sm text-sm text-white font-bold bg-black" disabled>
                <div className="flex items-center justify-center">
                    <div className="flex justify-center items-center space-x-1 text-sm text-white">
                        <svg fill='none' className="w-6 h-5 animate-spin" viewBox="0 0 32 32" xmlns='http://www.w3.org/2000/svg'>
                            <path clipRule='evenodd'
                                  d='M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z'
                                  fill='currentColor' fillRule='evenodd' />
                        </svg>
                        <div>LOADING</div>
                    </div>
                </div>
            </button>) : (
                <button type="button" className="inline-block w-1/2 md:w-1/4 p-3 text-sm font-bold text-center text-gray-600 transition duration-200 bg-white ease" disabled>
                    <div className="flex items-center justify-center w-full">
                        <div className="flex justify-center items-center space-x-1">
                            <svg fill='none' className="w-6 h-5 animate-spin" viewBox="0 0 32 32" xmlns='http://www.w3.org/2000/svg'>
                                <path clipRule='evenodd'
                                      d='M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z'
                                      fill='currentColor' fillRule='evenodd' />
                            </svg>
                            <div>LOADING</div>
                        </div>
                    </div>
                </button>
            ) }

        </div>
    )
}